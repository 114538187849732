.modal-header {
  display: flex;
  justify-content: space-between;
  .modal-title {
    font-size: 20px;
  }
  .close-button {
    background: none;
    border: none;
    color: black;
  }
  .close-button:active {
    background: none;
    border: none;
  }
  .close-button:focus {
    background: none;
    border: none;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
  }
  .message {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.modal-body.edit {
  .inputs {
    display: flex;
    flex-direction: column;
  }
}

@include tablet {
  .modal-body.edit {
    .inputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
    }
  }
}
