.calendar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;

  .rbc-event {
    &.incoming {
      background-color: var(--secondary-color);
    }

    &.outgoing {
      background-color: var(--primary-color);
    }
  }

  @include desktop {
    .calendarBody {
      min-width: 90%;
    }
  }
}