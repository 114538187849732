.details-jobpost {
  .jobpost-edit-container {
    background-color: var(--primary-color);
    color: white;
    padding: 2rem;


    .jobpost-edit {
      .jobpost-edit-data {
        margin: 1rem 0;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1rem;

        @include laptop {
          grid-template-columns: 1fr 1fr;

        }

        column-gap: 2rem;

        .jobpost-column {
          h2 {
            margin-bottom: 20px;
          }
        }

        .input-bar {
          grid-column: -1/1;

          .current-files {
            display: flex;
            gap: 1rem;
            margin-top: 10px;
            margin-bottom: 20px;
          }

          .drop {
            border: 2px dashed var(--secondary-color);
            height: 60px;

            svg {
              path {
                fill: var(--secondary-color);
              }
            }

            div {
              span {

                color: white;
                font-weight: 500;
                font-size: 15px;
              }
            }
          }

          h3 {

            margin-bottom: 20px;
          }
        }

        .title {
          label {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
          }

          margin-bottom: 15px;
        }

        .formGroup {
          display: flex;
          flex-flow: column;



          @include laptop {
            flex-flow: row;
            margin-bottom: 10px;
            gap: 1rem;
            justify-content: space-between;
          }

          .multi-options {
            span {
              margin-right: 10px;
            }
          }

          textarea,
          input[type="text"],
          input[type="date"],
          select {
            width: 100%;

            @include laptop {
              width: 50%;

            }
          }

          input[type="text"],
          select {
            height: 33px;
          }

          label {
            font-weight: bold;
            font-size: 18px;
          }
        }

        .contact-information {
          margin-top: 20px;

          display: flex;
          gap: 1rem;

          .contact-question {
            border-right: 1px solid var(--secondary-color);
            padding-right: 10px;

            label {
              margin-bottom: 10px;
              font-size: 18px;
              font-weight: bold;
            }

            .contact-options {
              display: flex;
              gap: 1rem;
              justify-content: space-between;
              margin-bottom: 10px;

              .approved {
                color: var(--secondary-color);
              }

              .denied {
                color: red;
              }
            }
          }

          .dates {
            display: flex;
            flex-flow: column;
            gap: 1rem;

            @include tablet {
              flex-flow: row;
            }

            .formGroup {}
          }
        }

        .employees-formGroup {
          grid-column: -1/1;

          .employee-bar-container {
            display: flex;
            flex-wrap: wrap;
            min-width: 50%;
            justify-content: space-between;

            .employee-bar {
              flex-flow: column;
              width: 100%;

              @include laptop {
                display: flex;
                flex-flow: row;
                gap: 2rem;
                align-items: center;
                width: 45%;
              }



              .employee-information-group {
                width: 100%;
              }
            }
          }



          .employee-bar {
            margin-bottom: 45px;

            .remove-button {
              width: 50%;
              border-bottom: 1px solid var(--secondary-color);
              padding-bottom: 20px;
            }

            .employees-bar-data {
              @include laptop {}

              margin: 1rem 0;

              label {
                margin-right: 10px;
              }

              input {
                float: right;
                width: 35%;
                height: 33px;
              }

              select {
                float: right;
                width: 35%;
                height: 33px;
              }
            }

            .jobpost-title {
              h3 {
                margin-bottom: 20px;
              }

              div {
                font-size: 16px;
                margin: 10px 0;

                span {
                  margin-right: 10px;
                  background-color: #696969;
                  color: white;
                  font-weight: bold;
                  padding: 6px 6px;
                  border-radius: 6px;
                }
              }
            }

            .jobpost-description {
              .formGroup {
                label {
                  margin: 5px 0;
                  color: #81d3c8cc;
                }

                div {
                  font-size: 16px;
                  max-width: 80%;
                }
              }
            }

            .add-inputs {
              &:hover {
                background-color: var(--secondary-color);
                color: black;
                cursor: pointer;
              }

              margin-top: 20px;
              width: 50%;
              min-width: 100px;
              height: 100px;
              display: grid;
              place-content: center;
              border: 2px dotted var(--secondary-color);
              padding: 2rem;
              border-radius: 99999px;
              margin-inline: auto;

              span {
                display: flex;
                gap: 10px;
              }

              @include laptop {
                margin-top: unset;
              }
            }
          }
        }
      }

      .edit-jobpost-actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }
    }
  }
}