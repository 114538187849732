.exchangesContainer {
  width: 100%;
  padding: 0 !important;
  margin: 0;
  background: #E4E9F7;
  margin-bottom: unset !important;

  .exchangeBody {
    .navigation-menu {
      height: 10vh;
      display: flex;
      justify-content: space-around;
      align-items: center;

      a {
        height: 100%;
        width: 100%;

        font-size: large;

        div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #11a08d;

          i {
            margin-right: 8px;
          }

          &.active {
            background-color: var(--secondary-color);
            color: white;

          }

          &:not(.active) {
            transition: 0.2s ease-in-out;

            &:hover {
              transform: scale(1.02);
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }

    .searchForm {
      display: flex;
      flex-flow: column;
      justify-content: center;

      .firstBar {
        width: 100%;
        background-color: var(--primary-color);
        display: flex;
        padding-block: 15px;
        padding-inline: 5vw;


        .primarySearch {
          width: 80%;
          display: flex;
          gap: 1rem;

          .dropdown {
            flex: 0.3;

            label {
              width: 100%;
              margin-bottom: 5px;
              color: white;
              font-weight: 300;
              font-size: 1rem;
            }

            select {
              width: 100%;
              font-size: 1rem;
            }
          }

          .searchBar {
            flex: 0.5;
            display: flex;


            .searchGroup {
              flex: 0.9;

              label {
                color: white;
                margin-bottom: 5px;
                font-size: 1rem;
              }

              input {
                width: 100%;
              }
            }

            .searchButton {
              flex: 0.1;
              align-self: flex-end;
            }
          }
        }
      }

      .secondBar {
        background-color: var(--primary-color);
        display: flex;
        width: 100%;

        padding-block: 15px;
        padding-inline: 5vw;

        .searchFilters {
          width: 80%;
          display: flex;
          gap: 1rem;

          .dropdown {
            flex: 0.3;

            label {
              width: 100%;
              margin-bottom: 5px;
              color: white;
              font-weight: 300;
              font-size: 1rem;
            }

            select {
              width: 100%;
              font-weight: 300;
              font-size: 1rem;
            }

            input {
              width: 100%;
            }
          }

          .searchBar {
            flex: 0.5;
            display: flex;

            .searchGroup {
              flex: 0.9;

              label {
                color: white;
              }

              input {
                width: 100%;
              }
            }

            .searchButton {
              flex: 0.1;
              align-self: flex-end;
            }
          }
        }
      }
    }
  }

  .employeesContainer {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    margin: 0;

    .employeesContainerHeader {
      width: 90%;
      padding: 1rem;
      color: black;

      .locationHeader {
        font-size: 36px;
        font-weight: 300;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
  }
}