header {
  .first-section {
    display: flex;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .light {
      background-color: #4bb7a9;
      color: white;

      span {
        i {
          margin: 0 5px;
        }

        padding: 10px 10px;
      }
    }

    .dark {
      background-color: #2a2a2a;
      color: white;

      span {
        padding: 10px 10px;
      }

      .socials {
        span {
          padding: 10px 10px;

          a {
            color: #fff;
          }
        }

        display: flex;
        flex-direction: row;
      }
    }
  }

  .second-section {
    width: 100%;

    .buttons-image {
      width: 100%;

      .buttons {
        margin: 10px 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .image {
        padding: 12px 18px;
        display: flex;
        justify-content: center;

        img {
          width: 190px;
        }
      }
    }

    .menu-items {
      display: none;
    }

    .hamburger {
      &::after {
        background-color: transparent;
      }

      &::before {
        background-color: transparent;
      }

      background-color: transparent;

      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px 10px;
      align-items: center;
      width: 100%;

      .icon {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 5px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 30px;
        }
      }
    }

    .menu-mobile-items {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      color: #000;
      z-index: 1000;
      width: 100%;

      span {
        font-size: 22px;
        margin: 5px 0;
      }
    }
  }
}

@include tablet {
  header {
    .first-section {
      .light {
        display: flex;
        flex-direction: row;
      }

      .dark {
        .contact {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .second-section {
      .buttons-image {
        width: 100%;
        display: flex;

        .buttons {
          align-items: center;
        }

        .image {
          width: 100%;

          align-items: center;

        }
      }

      .hamburger {
        display: none;
      }

      .menu-items {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
      }
    }
  }
}

@include laptop {
  header {
    .first-section {
      .light {
        justify-content: center;
      }

      .dark {
        display: flex;
        flex-direction: row;

        div {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .second-section {
      display: flex;
      flex-direction: row;

      .buttons-image {
        display: flex;
        flex-direction: row;

        div {
          width: 100%;
        }

        .image {
          padding: unset;
          width: 226px;
          box-shadow: 0px 20px 33px -22px rgba(0, 0, 0, 0.5);
        }
      }

      .menu-items {
        width: 83%;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;

        span {
          font-size: 20px;
        }
      }

      .hamburger {
        display: none;
      }
    }
  }
}