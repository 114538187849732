.create-users-container {
  min-width: 80%;
  .page-header {
    display: flex;
    flex-direction: column;
    height: fit-content;
    .page-title {
      color: var(--secondary-color);
      font-weight: var(--sub-header-weight);
      font-size: var(--default-font);
    }
    .page-desc {
      font-size: var(--medium-font);
      font-weight: var(--main-header-weight);
    }
  }
  .page-body {
    .user-details {
      background-color: var(--primary-color);
      color: white;
      padding: 2rem;
      .form-section {
        display: flex;
        flex-direction: column;
        .inputs {
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          label {
            margin: 5px 0;
          }
          input {
            margin: 5px 0;
          }
        }
      }
      .buttons-section {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        .create-user-button {
          background-color: $secondary-color;
          border: none;
          width: 77px;
        }
        .create-user-button:hover {
          background-color: $secondary-color;
        }
        .create-user-button:hover {
          background-color: $secondary-color;
        }
        .create-user-button:active {
          background-color: $secondary-color;
        }
      }
    }
  }
}

@include tablet {
  .create-users-container {
    .page-body {
      .user-details {
        .form-section {
          padding: 0 40px;
        }
      }
    }
  }
}

@include laptop {
  .create-users-container {
    .page-body {
      .user-details {
        .form-section {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          form {
            width: 50%;
          }
        }
      }
    }
  }
}
