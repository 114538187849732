.company-card {
  margin-bottom: 10px;
  display: flex;
  position: relative;

  flex-flow: row wrap;
  border-radius: 6px;
  gap: 0.5rem;


  background-color: white;
  color: black;

  &.incoming {
    background-color: var(--secondary-color);
  }

  &-border {
    display: grid;
    place-content: center;

    width: 100%;

    @include laptop {
      width: unset;
    }

    border-style: solid;
    border-width: 10px;
    border-radius: 4px;
    border-image: linear-gradient(60deg, #2a2a2a, #695e5e) 1;

    &.outgoing {
      border-image: linear-gradient(60deg, #128878, #4bb7a9) 1;
    }


    .companyImage {
      position: relative;
      padding: 10px;

      img {
        object-fit: contain;
        height: 200px;
        width: 200px;
        min-width: 200px;
      }
    }
  }

  &-header {
    padding: 1rem;

    .title {
      font-size: 26px;
      font-weight: 600;


      &::first-letter {
        text-transform: capitalize;
      }

      .employee-name {
        text-transform: capitalize;
      }
    }


    .employee {
      font-size: 26px;
      text-decoration: underline;
    }

    .company-card-body {
      .message {
        min-height: 80px;


        label {
          font-size: 22px;
        }

        .message-content {
          background-color: #f6f6f6;
          width: fit-content;
          min-height: 50px;
          padding: 10px 8px;


          font-size: 20px;

          &::first-letter {
            text-transform: capitalize;
          }

        }
      }

      .dates {
        font-size: 18px;

        span {
          margin-right: 5px;
        }
      }
    }

    .eventTime {
      margin-left: 5px;
      color: black;
      position: absolute;
      padding: 2px;
      border-radius: 6px;
      top: 10px;
      right: 10px;
    }
  }

  &-footer {
    padding: 1rem;
    align-self: flex-end;
    justify-content: flex-end;
    flex: 1;
    display: flex;

    span {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 0.5rem 0.25rem;
    }

    span.info {
      cursor: default;
    }

    .accept {
      background-color: #29b4a2;
    }

    .accept:hover {
      cursor: pointer;
    }

    .reject:hover {
      color: red;
      cursor: pointer;
    }
  }
}