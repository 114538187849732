.contact-multiselectdropdown {
    border-radius: 4px;
    height: 33px;

    &.is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    background-color: #f6f7f9;

    .multiselectdropdown-header {
        height: auto !important;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        display: flex;
        flex-wrap: wrap;
        border-width: 0;


        width: 100%;

        // @include laptop {
        //     // width: 200px;
        // }

        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-left: 4px;
        line-height: 33px;
        background-color: white;

        .second-svg {
            margin-left: auto;
            // width: 1.5rem;
            width: 18px;
            padding-right: 4px;
            color: black;
        }

        .multiselect-placeholder {
            color: black;
            // background-color: red;
            width: calc(100% - 20px);
            height: 100%;
            min-width: 180px;
        }

        .multiselect-item {


            display: flex;
            margin-right: 1rem;
            gap: 0.25rem;
            align-items: center;
            color: black;
            background-color: lightgray;
            border-radius: 4px;
            margin-bottom: 4px;
            padding: 4px;


        }
    }

    .multiselectdropdown-body {
        padding: 5px 0px;
        border-top: 1px solid #e5e8ec;
        display: none;
        background-color: #f6f7f9;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        max-height: 200px;
        overflow: auto;
        z-index: 2;
        position: absolute;
        color: black;

        width: calc(100vw - 8rem);

        @include medium {

            width: calc(100vw - 15rem);
        }

        @include desktop {
            width: 200px;
        }
    }

    .multiselectdropdown-body.open {
        display: block;




    }

    .multiselectdropdown-item {
        padding: 10px 15px;
    }

    .multiselectdropdown-item:hover {
        cursor: pointer;
        background-color: #efefef81;
    }

    .multiselectdropdown-item-dot {
        opacity: 0;
        color: #91a5be;
        transition: all 0.2s ease-in-out;
    }

    .multiselectdropdown-item-dot.selected {
        opacity: 1;
    }

    .icon {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
    }

    .icon.open {
        transform: rotate(180deg);
    }
}