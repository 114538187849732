@keyframes change {
  0% {
    background-image: url(../assets/images/2.jpg);
  }

  20% {
    background-image: url(../assets/images/3.jpg);
  }

  40% {
    background-image: url(../assets/images/4.jpg);
  }

  60% {
    background-image: url(../assets/images/5.jpg);
  }

  80% {
    background-image: url(../assets/images/6.jpg);
  }

  100% {
    background-image: url(../assets/images/7.jpg);
  }
}

.image-container {
  width: 100%;

  background-image: url(../assets/images/1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: change;
  animation-direction: alternate-reverse;
  animation-duration: 15s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  position: relative;
  overflow: hidden;

  .background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .component-container {
      width: 80%;

      .title {
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        span {
          font-size: 40px;
          color: #fff;
        }

        .line {
          margin: 10px 0;
          border-bottom: 1px solid #fff;
          width: 80%;
        }
      }

      .form {
        padding: 40px;

        form {
          display: flex;
          flex-direction: column;

          div {
            width: 100%;
            margin: 10px 0;
          }

          label {
            width: 100%;
            color: #fff;
            font-size: 15px;
            margin-bottom: 5px;
          }

          input {
            width: 100%;
            min-height: 33px;
            border: 1px solid #fff;
            border-radius: 4px;
          }

          .address-postnr {
            display: flex;

            .address {
              margin: 0 5px 0 0;
            }

            .postnr {
              margin: 0 0 0 5px;
              width: 50%;
            }
          }

          .region {
            display: flex;

            div {
              select {
                width: 100%;
                min-height: 33px;
                border-radius: 4px;
              }
            }

            .lan {
              margin: 0 5px 0 0;
              width: 60%;
            }

            .state {
              margin: 0 0 0 5px;
              width: 100%;
            }
          }

          .country {
            select {
              width: 100%;
              min-height: 33px;
              border-radius: 4px;
            }
          }

          .business-area-category {
            display: flex;

            div {
              select {
                width: 100%;
                min-height: 33px;
                border-radius: 4px;
              }
            }

            .business-area {
              margin: 0 5px 0 0;
            }

            .category {
              margin: 0 0 0 5px;
            }
          }

          .fileUploaderBar {
            max-width: 100%;

            label {
              margin-bottom: 10px;
            }

            .drop {
              border: 2px dashed var(--secondary-color);
              height: 100px;
              min-height: 60px;
              min-width: unset;
              max-width: unset;

              .file-types {
                min-width: fit-content;
              }


              svg {
                path {
                  fill: var(--secondary-color);
                }
              }

              div {
                span {

                  color: white;
                  font-weight: 500;
                  font-size: 15px;
                }
              }
            }
          }

          .terms-of-agreement {

            span,
            label {
              color: #fff;
            }

            label {
              margin-bottom: unset;
            }

            .terms-of-agreement-check {
              display: flex;
              align-items: center;

              input {
                width: unset;
                margin-right: 5px;
                accent-color: var(--secondary-color);
              }
            }
          }

          .remember-me {
            display: flex;
            padding: 10px 0;
            align-items: center;

            input {
              width: unset;
              margin-right: 5px;
            }

            label {
              margin-bottom: unset;
            }
          }

          .login-button {
            button {
              width: 100%;
            }

            button:hover {
              padding: 5px;
            }
          }

          .register-button {
            button:hover {
              padding: 5px;
            }
          }

          .forgot-password {
            span {
              color: #fff;
            }

            span:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }

          .fileUpload {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            color: white;

            div {
              input {
                width: unset;
                margin-right: 5px;
                min-height: unset;
              }

              input[type="checkbox"] {
                margin-left: 5px;
                accent-color: var(--secondary-color);
              }

              input[type="file"] {
                width: 100%;
              }
            }
          }
        }
      }

      .no-account {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px 0;

        .line {
          margin: 10px 0;
          border-bottom: 1px solid #fff;
          width: 80%;
        }

        span {
          font-size: 20px;
          color: #fff;
        }

        .register-button {
          margin: 10px 0;

          button {
            padding: 7px;
            transition: none;
          }

          button:hover {
            padding: 7px;
          }
        }
      }
    }
  }

  .b-g-background {
    background: linear-gradient(90deg,
        rgba(42, 42, 42, 0.8) 50%,
        rgba(75, 183, 169, 0.8) 50%);
  }

  .g-b-background {
    background: linear-gradient(90deg,
        rgba(75, 183, 169, 0.8) 50%,
        rgba(42, 42, 42, 0.8) 50%);
  }
}

.image-container.login-container {
  height: 150vh;
}

.image-container.signup-container {
  height: 230vh;
}

@include tablet {
  .image-container {
    .background {
      .component-container {
        width: 50%;
      }
    }
  }
}

@include laptop {
  .image-container {
    .background {
      .component-container {
        width: 33.33%;

        .form {
          form {
            .fileUpload {
              flex-wrap: nowrap;

              div {
                width: unset;
              }
            }
          }
        }
      }
    }
  }
}