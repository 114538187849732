.login {
  border: none;
  border-radius: unset;
  background-color: var(--secondary-color);
  color: white;
  padding: 5px;
  border-radius: 2px;

  &.maize {
    background-color: #f7c922;
  }

  &.blue {
    background-color: #073255;
  }
}

.login:active {
  transform: translate(0px, 5px);
  background-color: var(--secondary-color);
}

.login:focus {
  background-color: var(--secondary-color) !important;
}

.login:hover {
  background-color: var(--secondary-color);
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.login:disabled,
.login[disabled] {
  background-color: var(--secondary-color);
  border-radius: 2px;
  color: white;
  padding: 5px;
}

.login.loading {
  width: 143px;
}

.save-loading {
  width: 72.67px;
}

.register {
  border: none;
  border-radius: unset;
  background-color: #696969;
  color: white;
  padding: 5px;
  border-radius: 2px;
  transition: transform, padding 0.5s;
}

.register:active {
  background-color: #696969;
  transform: translate(0px, 5px);
}

.register:focus {
  background-color: #696969;
}

.register:hover {
  background-color: #696969;
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.register:disabled,
.register[disabled] {
  border: none;
  background-color: var(--primary-color);
  padding: 5px;
  border-radius: 2px;
  color: white;
}

.lighter {
  border: none;
  border-radius: unset;
  background-color: #696969;
  color: white;
  padding: 5px;
  border-radius: 2px;
  transition: transform, padding 0.5s;
}

.lighter:active {
  transform: translate(0px, 5px);
  background-color: var(--primary-color);
}

.lighter:focus {
  background-color: var(--primary-color) !important;
}

.lighter:hover {
  background-color: var(--primary-color);
  padding: 10px;
}

.lighter:disabled,
.lighter[disabled] {
  border: none;
  background-color: var(--primary-color);
  padding: 5px;
  border-radius: 2px;
  color: white;
}

.circle {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-color: var(--secondary-color);
  font-size: 22px;
  background-color: var(--secondary-color);
  color: white;
  padding: 5px;
  transition: transform, padding, height, width 0.5s;



}

.circle:active {
  transform: translate(0px, 5px);
  background-color: var(--secondary-color);
}

.circle:focus {
  background-color: var(--secondary-color) !important;
}

.circle:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  padding: 10px;
  height: 55px;
  width: 55px;

}




.circle:disabled,
.circle[disabled] {
  background-color: var(--secondary-color);
  border-radius: 2px;
  color: white;
  padding: 5px;
}

.circle.loading {
  width: 143px;
}

.delete {
  border: none;
  border-radius: unset;
  background-color: #c70000;
  color: white;
  padding: 5px;
  border-radius: 2px;
  width: 63px;
  transition: transform, padding 0.5s;
}

.delete:active {
  transform: translate(0px, 5px);
  background-color: #c70000;
}

.delete:focus {
  background-color: #c70000 !important;
}

.delete:hover {
  background-color: #c70000;
  transform: scale(1.13);
  transition: transform 0.5s ease-in-out;
}

.delete:disabled,
.delete[disabled] {
  background-color: #c70000;
  border-radius: 2px;
  color: white;
  padding: 5px;
}

.delete.loading {
  width: 143px;
}

.save {
  border: none;
  border-radius: unset;
  background-color: $secondary-color;
  color: white;
  padding: 5px;
  border-radius: 2px;
  width: 63px;
  transition: transform, padding 0.5s;
}

.save:active {
  transform: translate(0px, 5px);
  background-color: $secondary-color;
}

.save:focus {
  background-color: $secondary-color !important;
}

.save:hover {
  background-color: $secondary-color;
  transform: scale(1.13);
  transition: transform 0.5s ease-in-out;
}

.save:disabled,
.save[disabled] {
  background-color: $secondary-color;
  border-radius: 2px;
  color: white;
  padding: 5px;
}

.save.loading {
  width: 143px;
}