$margin: 10px;
$padding: 10px;
$gutter: 10px;

body {
    font-family: 'Poppins', sans-serif;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 350px;
    background: var(--primary-color);
    z-index: 100;
    transition: all 0.5s ease;

    .menu {
        position: absolute;
        top: 0;
        left: 23px;
        font-size: 2rem;
        color: #11101d;
        padding: 5px;
        cursor: pointer;

        i {
            color: white;
        }
    }

    &.close {
        width: 78px;
    }

    .logo-details {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;

        i {
            font-size: 30px;
            color: #fff;
            height: 50px;
            min-width: 78px;
            text-align: center;
            line-height: 50px;
        }

        .logo_name {
            font-size: 22px;
            color: #fff;
            font-weight: 600;
            transition: 0.3s ease;
            transition-delay: 0.1s;
            margin-inline: auto;
        }
    }

    &.close .logo-details .logo_name {
        transition-delay: 0s;
        opacity: 0;
        pointer-events: none;
    }

    .nav-links {
        height: 100%;
        padding: 30px 0 150px 0;
        overflow: auto;
    }

    &.close .nav-links {
        overflow: visible;
    }

    .nav-links {
        &::-webkit-scrollbar {
            display: none;
        }


        .marknad-button {
            background-color: var(--secondary-color);
        }

        li {
            position: relative;
            list-style: none;
            transition: all 0.4s ease;

            &:hover {
                background: #333;
            }

            &:first-child {
                .iocn-link {


                    a {
                        &.active {
                            &:hover {

                                span,
                                i {
                                    color: white;
                                }



                            }

                            span {
                                font-weight: 600;
                                color: var(--primary-color);
                            }

                            i {
                                color: var(--primary-color);
                            }

                        }
                    }
                }


            }

            .iocn-link {
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    &.active {
                        span {
                            font-weight: 600;
                            color: var(--secondary-color);
                        }

                        i {
                            color: var(--secondary-color);
                        }
                    }
                }


                &.notification {
                    position: relative;

                    a {
                        .notification-number {
                            position: absolute;
                            top: 0px;
                            left: 38px;
                            background-color: rgba(212, 19, 13, 1);
                            color: #fff;
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            display: grid;
                            place-content: center;
                            font-size: 16px;
                            font-family: Arial, Helvetica, sans-serif;
                        }


                    }
                }


            }
        }
    }

    &.close .nav-links li .iocn-link {
        display: block;
    }

    .nav-links li {
        i {
            height: 50px;
            min-width: 78px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            transition: all 0.3s ease;
        }

        &.showMenu i.arrow {
            transform: rotate(-180deg);
        }
    }

    &.close .nav-links i.arrow {
        display: none;
    }

    .nav-links li a {
        display: flex;
        align-items: center;
        text-decoration: none;

        .link_name {
            font-size: 18px;
            font-weight: 400;
            color: #fff;
            transition: all 0.4s ease;
        }
    }

    &.close .nav-links li a .link_name {
        opacity: 0;
        pointer-events: none;
    }

    .nav-links li {
        .sub-menu {
            padding: 6px 6px 14px 80px;
            margin-top: -10px;
            background: var(--primary-color);
            display: none;

        }

        &.showMenu .sub-menu {
            display: block;


        }

        .sub-menu a {
            color: #fff;
            font-size: 15px;
            padding: 5px 0;
            white-space: nowrap;
            opacity: 0.6;
            transition: all 0.3s ease;


            &.active {

                color: var(--secondary-color);

                font-weight: 500;

            }





            &:hover {
                opacity: 1;
            }
        }
    }

    &.close .nav-links li {
        .sub-menu {
            position: absolute;
            left: 100%;
            top: -10px;
            margin-top: 0;
            padding: 10px 20px;
            border-radius: 0 6px 6px 0;
            opacity: 0;
            display: block;
            pointer-events: none;
            transition: 0s;
        }

        &:hover .sub-menu {
            top: 0;
            opacity: 1;
            pointer-events: auto;
            transition: all 0.4s ease;
        }
    }

    .nav-links li .sub-menu .link_name {
        display: none;
    }

    &.close .nav-links li .sub-menu .link_name {
        font-size: 18px;
        opacity: 1;
        display: block;
    }

    .nav-links li {
        .sub-menu.blank {
            opacity: 1;
            pointer-events: auto;
            padding: 3px 20px 6px 16px;
            opacity: 0;
            pointer-events: none;
        }

        &:hover .sub-menu.blank {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .profile-details {
        position: fixed;
        bottom: 0;
        width: 350px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--secondary-color);
        padding: 12px 0;
        transition: all 0.5s ease;
    }

    &.close .profile-details {
        background: none;
        width: 78px;
    }

    .profile-details {
        .profile-content {
            display: flex;
            align-items: center;
        }

        img {
            height: 52px;
            width: 52px;
            object-fit: contain;
            border-radius: 16px;
            margin: 0 14px 0 12px;
            background: var(--secondary-color);
            transition: all 0.5s ease;
        }
    }

    &.close .profile-details img {
        padding: 10px;
    }

    .profile-details {

        .profile_name,
        .job {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            white-space: nowrap;
        }
    }

    &.close .profile-details {

        i,
        .profile_name,
        .job {
            display: none;
        }
    }

    .profile-details .job {
        font-size: 12px;
    }
}

.home-section {
    position: relative;
    background: #E4E9F7;
    min-height: 100vh;
    left: 350px;
    width: calc(100% - 350px);
    transition: all 0.5s ease;

    >* {
        padding: 1em;
        padding-inline: 5vw;
        padding-bottom: 60px;
    }


}


.sidebar.close~.home-section {
    left: 78px;
    width: calc(100% - 78px);
}

@media (max-width: 420px) {
    .sidebar.close .nav-links li .sub-menu {
        display: none;
    }
}

@include navbar {
    .sidebar {
        width: 100%;

        .profile-details {
            width: 100%;
        }

        .logo-details .logo_name {
            margin-inline: auto;
        }

        &.close {
            width: 0;

            .nav-links {
                display: none;
            }

            .menu {
                margin-top: 9px;
                width: 100vw;
                padding-right: 10px;
            }

        }

        .menu {
            left: 90%;
            margin-top: 0px;

            i {
                color: white;
            }
        }

        &.close {

            .logo-details {
                background-color: var(--secondary-color);
                width: 100vw;
            }

            .logo-details .logo_name {
                opacity: 1;
                color: black;
                margin-inline: auto;
            }

            .menu {
                left: 90%;



                i {
                    float: right;
                    color: black;
                }
            }
        }
    }

    .sidebar~.home-section {
        display: none;
    }

    .sidebar.close~.home-section {
        left: 0;
        width: 100%;
        display: block;
        margin-top: 60px;
    }

}