.jobposts {

  .jobposts-container {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    -webkit-animation: slideY 0.6s both;
    animation: slideY 0.6s both;
  }

}