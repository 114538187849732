footer {
  .first-section {
    display: flex;
    flex-direction: column;
    width: 100%;

    .left {
      padding: 0 10px;

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;

        img {
          width: 50%;
          height: 50%;
        }
      }

      .description {
        margin: 20px 0;

        &-title {
          margin: 10px 0;

          span {
            font-size: 20px;
          }
        }

        &-body {
          margin: 10px 0;

          span {
            font-style: italic;
          }
        }
      }
    }

    .right {
      padding: 0 10px;

      .platform {
        margin: 20px 0;

        &-title {
          padding: 10px 0;

          span {
            font-size: 20px;
          }
        }

        &-buttons {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          button {
            margin: 5px 0;
          }
        }
      }

      .support {
        margin: 20px 0;

        &-title {
          padding-bottom: 10px;

          span {
            font-size: 20px;
          }
        }

        &-links {
          display: flex;
          flex-direction: column;

          span {
            margin: 5px 0;
          }
        }
      }

      .contact {
        margin: 20px 0;

        &-title {
          padding-bottom: 10px;

          span {
            font-size: 20px;
          }
        }

        &-links {
          display: flex;
          flex-direction: column;

          span {
            margin: 5px 0;
          }
        }

        &-socials {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          span {
            a {
              color: #000;
            }

            margin: 0 10px;
          }
        }
      }
    }
  }

  .second-section {
    display: flex;

    span {
      align-self: center;
      color: white;
      font-size: 15px;

      a {
        text-decoration: none;
        color: white;
        font-weight: bold;
      }
    }

    div {
      padding: 5px 5px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .dark {
      background-color: #2a2a2a;
    }

    .light {
      background-color: #4bb7a9;
    }
  }
}

@include tablet {
  footer {
    .first-section {
      display: flex;
      flex-direction: row;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        flex-direction: row;

        .platform {
          &-title {
            padding: 0 0 10px 0;
          }
        }
      }
    }
  }
}

@include laptop {
  footer {
    .first-section {
      .right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        div {
          width: 100%;
        }
      }
    }
  }
}