.create-cat-container {
  .page-header {
    display: flex;
    flex-direction: column;
    height: fit-content;

    .page-title {
      color: var(--secondary-color);
      font-weight: var(--sub-header-weight);
      font-size: var(--default-font);
    }

    .page-desc {
      font-size: var(--medium-font);
      font-weight: var(--main-header-weight);
    }
  }

  .categories-form {
    background-color: var(--primary-color);
    color: white;
    padding: 2rem;

    .col {
      width: 100%;

      .inputs {
        display: flex;
        flex-direction: column;

        label {
          margin: 10px 0;
        }

        input {
          margin: 10px 0;
        }
      }
    }

    .button-section {
      margin: 10px 0 0 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        width: 63px;
      }

      button:hover {
        width: unset;
      }
    }
  }
}

@include tablet {
  .create-cat-container {
    .page-header {
      margin: 20px 0;
    }

    .categories-form {
      width: 70%;

      .col {
        padding: 20px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .inputs {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}

@include desktop {
  .create-cat-container {
    height: 100%;
    padding-right: 50px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
  }
}