.statisticCard {
  border: 0;
  margin-top: 30px;
  border-radius: 6px;
  color: #333;
  background-color: #fff;

  .cardHeader {
    text-align: right;
    margin: 0 15px;
    padding: 0;
    position: relative;

    .cardIcon {
      border-radius: 3px;
      background-color: #999;
      padding: 15px;
      margin-top: -20px;
      margin-right: 15px;
      float: left;

      &.primary {
        background: linear-gradient(60deg, #695e5e, #2a2a2a);
      }

      &.secondary {
        background: linear-gradient(60deg, #128878, #4bb7a9);
      }

      &.danger {
        background: linear-gradient(60deg, red, #4bb7a9);
      }

      i {
        font-size: 36px;
        line-height: 56px;
        width: 56px;
        height: 56px;
        text-align: center;
        color: white;
      }
    }
  }

  .cardData {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .cardTitle {
      padding-bottom: 1rem;
      font-size: xx-large;
      font-weight: bold;
    }

    p {
      margin: 5px;
      text-align: center;
    }
  }


}