.employees {
  .employeesList {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    -webkit-animation: slideY 0.6s both;
    animation: slideY 0.6s both;

  }
}


.employee-marknad-modal-body {

  .login {
    float: right;
    margin: 20px;
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: var(--secondary-color);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px var(--secondary-color);
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}