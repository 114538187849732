$primary-color: #2a2a2a;
$secondary-color: #2f9486;
// $secondary-color: #4bb7a9;
$primary-font: "Montserrat", sans-serif;
$sub-header-weight: 500;
$main-header-weight: 300;
$default-weight: 400;
$link-weight: 500;
$sub-header-font: 20px;
$main-header-font: 40px;
$default-font: 14px;
$medium-font: 30px;
@import "mixins.scss";
@import "react-big-calendar/lib/sass/styles";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

//homepage
@import "homepage.scss";
@import "header.scss";
@import "footer.scss";
@import "button.scss";
@import "login.scss";
@import "dropdown.scss";
@import "settings.scss";

//companyDashboard
@import "dashboardHeader.scss";
@import "companyProfile.scss";
@import "companyCalendar.scss";

//employee
@import "employeeCard.scss";
@import "employees.scss";
@import "employeesOverview.scss";
//this is the file for register/edit/details employee
@import "employee.scss";
@import "exchanges.scss";
@import "modal.scss";

//terms of use style
@import "termsOfUse.scss";

//notifications page
@import "notifications.scss";

//dashboard components
@import "table.scss";
@import "statisticCard.scss";
@import "exchangeCard.scss";
@import "progress.scss";
@import "tooltip.scss";
@import "pill.scss";
@import "notificationCard.scss";
@import "loading.scss";
@import "multiselect.scss";

@import "unauthorized.scss";
@import "createCompany.scss";

@import "createUsers.scss";

@import "registerEmployees.scss";

@import "editCompanies.scss";

@import "mobileCompanyCard.scss";

//jobpost
@import "jobpost.scss";
@import "jobposts.scss";
@import "jobpostDetails.scss";
@import "jobpostEdit.scss";
@import "jobCard.scss";
@import "jobMarketCard.scss";
@import "jobDetails.scss";

@import "nsidebar.scss";

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --primary-font: #{$primary-font};
  --sub-header-weight: #{$sub-header-weight};
  --main-header-weight: #{$main-header-weight};
  --default-weight: #{$default-weight};
  --link-weight: #{$link-weight};
  --sub-header-font: #{$sub-header-font};
  --main-header-font: #{$main-header-font};
  --default-font: #{$default-font};
  --medium-font: #{$medium-font};
}

body {
  @include desktop {
    font-family: var(--primary-font);
    font-weight: var(--default-weight);
    font-size: var(--default-font);
  }
}

.mediumHeader {
  font-size: var(--medium-font);
  color: white;
  margin-bottom: 14px;
}

.subheader {
  color: var(--secondary-color);
  line-height: 1;
  font-size: 1rem;
  font-weight: 700;
}

.secondaryColor {
  color: var(--secondary-color);
}

.flex {
  display: flex;
  gap: 1rem;
  // font-size: 1.25em;
  line-height: .05em;
  align-items: center;
}

.mediumHeader::after {
  content: "";
  display: block;
  // margin: 0 auto;
  width: 50%;
  padding-top: 8px;
  border-bottom: 2px solid var(--secondary-color);
}

.underline {
  color: white;
}


.page-item {
  a {

    color: black;
  }

  .page-link {

    border-color: none;
  }

  &.active {
    color: white;

    .page-link {
      border-color: none;
      background-color: var(--secondary-color);
    }
  }


}



.emptyPlaceholder {
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 400px;

  a {
    margin-inline: auto;
    margin-top: 10px;
  }
}

.underline::after {
  content: "";
  display: block;
  width: 50%;
  padding-top: 10px;
  border-bottom: 1px solid var(--secondary-color);
  // border-bottom: 1px solid rgba(47, 148, 134, 0.8);
}

.subHeader {
  color: var(--secondary-color);
  font-weight: var(--sub-header-weight);
  font-size: var(--default-font);

  @include desktop {
    color: var(--secondary-color);
    font-weight: var(--sub-header-weight);
    font-size: var(--sub-header-font);
  }
}

.header {
  font-size: var(--medium-font);
  font-weight: var(--main-header-weight);

  @include tablet {
    font-size: var(--main-header-font);
    font-weight: var(--main-header-weight);
  }

  @include desktop {
    font-size: var(--main-header-font);
    font-weight: var(--main-header-weight);
  }
}

// .secondaryBackground {
//   color: white;
//   background-color: var(--secondary-color);
// }

.action {
  cursor: pointer;

  &.danger {
    &:hover {
      color: red;
    }
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
ul,
dd {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
  }
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

ul,
ol {
  padding: 0;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}