.mainContainer {
  width: 100%;

  .tableCard {
    min-width: 100%;
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    color: #333;
    background-color: #fff;

    padding: 1rem 20px;
    position: relative;

    display: flex;
    flex-flow: column;

    .tableCardHeader {
      border-radius: 3px;
      margin-top: -40px;
      padding: 15px;
      margin-right: 15px;
      margin-left: 15px;
      color: #fff;

      background: linear-gradient(60deg, #2a2a2a, #695e5e);

      &.secondary {
        background: linear-gradient(60deg, #128878, #4bb7a9);
      }
    }

    .tableCardBody {
      .companies-link:hover {
        cursor: pointer;
        text-decoration: underline;
        color: var(--primary-color);
      }

      flex: 1 1 auto;
      overflow-x: auto;

      .responsiveTable {
        table {
          width: 100%;
          border-collapse: collapse;

          thead {
            tr {
              border-bottom: 1px solid var(--primary-color);

              th {
                font-size: 1rem;
                padding: 12px 8px;
                vertical-align: middle;
                color: var(--primary-color);
                text-align: center;
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid black;

              td {
                font-size: 1rem;
                padding: 12px 8px;
                vertical-align: middle;
                text-align: center;

                .delete-icon:hover {
                  color: #c70000;
                }

                .edit-icon:hover {
                  color: $secondary-color;
                }
              }
            }
          }
        }

        &.secondary {
          thead {
            tr {
              border-bottom: 1px solid var(--secondary-color);

              th {
                border-bottom: 1px solid var(--secondary-color);
                color: var(--secondary-color);
              }
            }
          }
        }
      }
    }
  }
}