.settings {
  .settings-page {
    display: grid;
    gap: 1rem;

    .settings-form {
      background-color: var(--primary-color);
      padding: 1rem;
      border-radius: calc(0.375rem - 1px);

      form {
        color: white;
        display: grid;
        row-gap: 1rem;
        column-gap: 2rem;

        .form-group {
          margin-block: 10px;

          label {
            width: 100%;
            margin-bottom: 8px;
          }

          input,
          select {
            height: 33px;
            width: 100%;
          }

          input:disabled {
            color: white;
            font-weight: 780;
          }
        }

        .col {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          padding-bottom: 20px;


          &.notiser {
            display: flex;
            flex-flow: column;

            @include laptop {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 1rem;
              padding-bottom: 20px;
            }

          }

          .buttons {
            grid-column: 1/-1;

          }

          h3 {
            grid-column: 1/-1;
            font-weight: 600;
            font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
              sans-serif;
            color: var(--secondary-color);
          }

          .buttons {
            margin-top: auto;
            align-self: flex-end;
          }
        }
      }
    }

    .settings-profile {
      background-color: var(--primary-color);
      color: white;
      display: flex;
      flex-flow: column;
      gap: 50px;
      justify-content: center;
      border-radius: calc(0.375rem - 1px);
      padding: 1rem;



      height: fit-content;

      .settings-profile-image {
        width: 100%;




        .login {
          margin-top: 20px;
        }

        .upload-file {
          background-color: var(--secondary-color);
          height: 33px;
          line-height: 33px;
          padding-inline: 1rem;
          margin-top: 20px;

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition: transform 0.5s ease-in-out;
            background-color: #238073;
          }

          i {
            margin-right: 10px;
          }
        }

        form {
          display: flex;
          flex-flow: column;
          align-items: center;

          .drop {
            border: 2px dashed var(--secondary-color);
            height: 60px;
            width: 100%;

            .file-types {
              max-width: 200px;
            }

            svg {
              path {
                fill: var(--secondary-color);
              }
            }

            div {
              span {

                color: white;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }

          .settings-image-holder {
            width: 200px;
          }
        }

        .company-statistics {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .stats {
            text-align: center;

            .number {
              font-size: 1.1rem;
              font-weight: 700;
              display: block;
              letter-spacing: 0.025em;
            }

            span {
              font-size: 0.875rem;
              color: #adb5bd;
            }
          }
        }
      }
    }
  }

  @include laptop {
    .settings-page {
      grid-template-columns: 2fr 1fr;
    }
  }
}