.details-jobpost {
  .jobpost-details-container {
    background-color: var(--primary-color);
    color: white;
    padding: 2rem;

    .jobpost-details {

      margin: 1rem 0;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 1rem;

      @include laptop {
        grid-template-columns: 1fr 1fr;
      }

      .input-bar {
        grid-column: -1/1;
      }

      .jobpost-data {

        margin: 1rem 0;

        .title {
          label {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
          }

          margin-bottom: 15px;
        }

        .formGroup {
          margin-bottom: 10px;

          label {
            font-weight: bold;
            font-size: 18px;
          }
        }

        .contact-information {
          margin-top: 20px;
          display: flex;
          gap: 2rem;

          .contact-question {
            border-right: 1px solid var(--secondary-color);
            padding-right: 20px;

            label {
              margin-bottom: 10px;
              font-size: 18px;
              font-weight: bold;
            }

            .contact-options {
              display: flex;
              gap: 1rem;
              justify-content: space-between;
              margin-bottom: 10px;

              .approved {
                color: var(--secondary-color);
              }

              .denied {
                color: red;
              }
            }
          }

          .dates {
            display: flex;
            flex-flow: column;

            @include tablet {
              flex-flow: row;
            }

            gap: 2rem;
          }
        }

        .employees-formGroup {

          h2 {
            color: var(--secondary-color);
            margin: 30px 0;
            border-bottom: 1px dashed var(--secondary-color);
            max-width: 80%;
            padding-bottom: 5px;

            @include desktop {
              max-width: 35%;
            }
          }

          .employee-bar {
            margin-bottom: 45px;

            .jobpost-title {
              h3 {
                margin-bottom: 20px;
              }

              div {
                font-size: 16px;
                margin: 10px 0;

                span {
                  margin-right: 10px;
                  background-color: #696969;
                  color: white;
                  font-weight: bold;
                  padding: 6px 6px;
                  border-radius: 6px;
                }
              }
            }

            .jobpost-description {
              .formGroup {
                label {
                  margin: 5px 0;
                  color: #81d3c8cc;
                }
              }
            }
          }
        }
      }
    }
  }
}