.overviewContainer {

  .overviewContainerData {
    display: flex;
    flex-flow: column;

    .statisticCards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
      padding: 0.5rem 0;
    }

    .sentEmployee {
      h2 {
        margin: 15px 0;
      }

      .incomingEmployees {
        display: flex;
        flex-flow: column;
        gap: 1rem;

        .progressCard {
          margin: 10px 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          word-wrap: break-word;

          .imageColumn {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;

            i {
              &.incoming {
                color: #4bb7a9;
              }
            }
          }

          .infoColumn {
            margin-left: -0.5rem;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            min-height: 1px;

            span {

              text-transform: capitalize;

              &:not(:first-child) {
                margin-left: 5px;
              }
            }

            gap: 0.5rem;
          }
        }

        .seeAllEmployees {
          text-align: center;

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }

          i {
            padding-left: 5px;
          }

          &.incoming {
            i {
              color: #4bb7a9;
            }
          }
        }
      }
    }
  }

  @include desktop {
    .overviewContainerData {

      .statisticCards {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}