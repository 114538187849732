.market-jobpost-details {
  .market-jobpost-details-container {
    position: relative;

    .posted-date {
      position: absolute;
      right: 0;
      padding-right: 2rem;
      text-align: right;
      font-size: 14px;
      opacity: 0.6;
    }

    background-color: whitesmoke;
    padding: 2rem;

    .market-jobpost-details-header {
      display: flex;
      flex-flow: column;

      gap: 1rem;

      img {
        width: 120px;
        height: 120px;
        object-fit: contain;
        background-color: var(--secondary-color);
        padding: 5px;
        border-radius: 8px;
      }

      .market-jobpost-title {
        padding: 0.5rem 0;
        width: 100%;
        display: flex;
        flex-flow: column;
        gap: 1rem;

        .company-location-details {

          i {
            color: var(--secondary-color);
            margin-right: 5px;
          }

          span {
            margin-right: 4px;
            line-height: 20px;

            &:first-child {
              color: var(--secondary-color);
              font-weight: bold;
              font-size: 16px;
            }
          }
        }

        .title-container {
          width: 100%;
          display: flex;
          gap: 1rem;

          h2 {
            &::first-letter {
              text-transform: capitalize;
            }
          }

          span {
            white-space: nowrap;
            font-size: 12px;
          }
        }
      }
    }

    .mediumHeader {
      color: black;
    }

    .market-jobpost-container {
      display: flex;
      flex-flow: column;

      .jobpost-data {
        margin: 1rem 0;
        display: flex;
        flex-flow: column;
        gap: 1.5rem;

        .title {
          label {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
          }

          margin-bottom: 15px;
        }

        .jobpost-information-bar {
          margin-top: 20px;
          border: 1px solid var(--secondary-color);
          border-radius: 8px;
          display: flex;
          flex-wrap: wrap; //wrap on mobile
          padding: 0 16px;
          align-items: center;
          justify-content: space-between;

          .information-bar {
            display: grid;
            place-items: center;
            grid-template-columns: 1fr 1fr;
            width: 100%;

            .information-title {
              font-size: 14px;
              line-height: 40px;
              white-space: nowrap;
            }

            .information-data {
              font-size: 16px;
              font-weight: 600;
              margin-top: -8px;
              white-space: nowrap;
            }
          }

          .information-bar:not(:last-child) {
            border-bottom: 1px solid var(--secondary-color); // this one on mobile
          }
        }

        .job-overview {
          label {
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 28px;
          }


        }

        .contact-information {
          margin-top: 20px;

          display: flex;
          gap: 2rem;

          .contact-question {

            label {
              margin-bottom: 10px;
              font-size: 18px;
              font-weight: bold;
            }

            .contact-options {
              display: flex;
              gap: 1rem;
              justify-content: space-between;
              margin-bottom: 10px;

              .approved {
                margin-left: 8px;
                color: var(--secondary-color);
              }

              .denied {
                color: red;
              }
            }
          }

          .dates {
            display: flex;
            flex-flow: column;

            @include tablet {
              flex-flow: row;
            }

            gap: 2rem;
          }
        }

        .employees-formGroup {

          h2 {
            color: var(--secondary-color);
            margin: 30px 0;
            border-bottom: 1px dashed var(--secondary-color);
            max-width: 80%;
            padding-bottom: 5px;

            @include desktop {
              max-width: 35%;
            }
          }

          .employee-bar {
            margin-bottom: 45px;

            .jobpost-title {
              h3 {
                margin-bottom: 20px;
              }

              div {
                font-size: 16px;
                margin: 10px 0;

                span {
                  margin-right: 10px;
                  background-color: #696969;
                  color: white;
                  font-weight: bold;
                  padding: 6px 6px;
                  border-radius: 6px;
                }
              }
            }

            .jobpost-description {
              .formGroup {
                label {
                  margin: 5px 0;
                  color: #81d3c8cc;
                }

              }
            }
          }
        }
      }
    }
  }

  @include tablet {
    .market-jobpost-details-container {
      .market-jobpost-details-header {
        flex-flow: row;

        .market-jobpost-title {
          width: 70%;

          .company-location-details {
            margin-top: auto;
          }
        }
      }

      .market-jobpost-container {
        .jobpost-data {
          .jobpost-information-bar {
            flex-wrap: nowrap;

            .information-bar {
              grid-template-columns: 1fr;
              height: 67px;

              &:not(:last-child) {
                border-right: 1px solid var(--secondary-color); //this on pc
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}