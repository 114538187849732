.job-card {
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-flow: column;

  &:hover {
    transform: scale(1.02);
  }

  img {
    padding: 10px;
    background-color: var(--secondary-color);
    border-radius: 8px 8px 12px 12px;
    object-fit: contain;
    width: 300px;
    height: 300px;
  }

  .job-card-body {
    padding: 0 16px 20px 16px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;

    .job-card-header {
      display: flex;
      align-items: flex-start;

      .job-card-settings {
        width: 4px;
        height: 4px;
        border: 0;
        padding: 0;
        border-radius: 50%;
        margin-left: auto;
      }
    }

    .job-card-title {
      font-weight: 600;
      margin-top: 16px;
      font-size: 20px;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .job-card-description {
      margin: 0.5rem 0;

      .emp-active {
        display: flex;
        flex-flow: column;
        gap: 4px;

        .text-success {
          font-size: 16px;
          font-weight: 600;
        }

        .text-light {
          label {
            font-weight: 500;
            font-size: 16px;
            margin-right: 5px;
          }
        }
      }

      .emp-passive {
        display: flex;
        flex-flow: column;
        gap: 10px;

        .text-danger {
          font-size: 17px;
          font-weight: 600;
        }

        .text-light {

          font-weight: 500;
          font-size: 16px;
          margin-right: 5px;

        }
      }
    }

    .job-card-details {
      display: flex;
      gap: 0.5rem;

      .details-pill {
        background-color: white;
        color: black;
        font-weight: 500;
        font-size: 11px;
        font-weight: 500;
        padding: 6px 8px;
        border-radius: 4px;
      }
    }

    .job-card-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 4px;

      .passive {
        background-color: initial;
        color: #81818d;
      }
    }
  }
}