.registerEmployee {

  .registerEmployeeForm {
    color: white;
    background-color: var(--primary-color);
    padding: 1rem;
    padding-bottom: 5rem;

    form {
      .formInputs {
        display: flex;
        flex-flow: column;

        .col {
          padding: 1rem;

          .multiselect-clicked-options {
            flex-flow: column;
            margin-top: 20px;


            .multiselect-item {
              display: flex;
              margin-right: 1rem;
              gap: 0.35rem;
              align-items: center;
              color: black;
              background-color: whitesmoke;
              border-radius: 4px;
              margin-bottom: 4px;
              padding: 4px;
              width: fit-content;

              span {
                cursor: pointer;

                &:hover {
                  background-color: #DC3545;

                  svg {
                    color: white;
                  }
                }

              }


            }
          }

          .certifiedRow {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            justify-content: space-between;
            margin-top: 1rem;
          }

          .checkboxReverse {
            display: flex;
            gap: 0.5rem;

            width: 100%;
            justify-content: space-between;

            input[type="checkbox"] {
              height: 22px;
              width: 22px;
              accent-color: var(--secondary-color);
            }
          }

          .multiselect-clicked-options {
            flex-flow: column;

            .multiselect-item {
              display: flex;
              margin-right: 1rem;
              gap: 0.25rem;
              align-items: center;
              color: black;
              background-color: whitesmoke;
              border-radius: 4px;
              margin-bottom: 4px;
              padding: 4px;
              width: fit-content;

              span {
                cursor: pointer;

                &:hover {
                  background-color: #DC3545;

                  svg {
                    color: white;
                  }
                }

              }


            }
          }


          .formGroup {

            display: flex;
            flex-flow: column;
            justify-content: space-between;




            label {
              margin-bottom: 5px;
            }

            input:not([type="checkbox"]),
            select {
              height: 33px;
            }

            input[type="checkbox"] {
              height: 26px;
              width: 26px;
              accent-color: var(--secondary-color);
            }
          }

          .skills {
            .multiselect {
              display: flex;
              flex-flow: column;

              .selectedOptions {
                padding: 0.5rem;
                background-color: white;
                color: black;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 0.3rem;

                height: auto;

                .select {
                  background-color: var(--secondary-color);
                  color: white;

                  span {
                    padding: 0 5px;
                    color: black;

                    &:hover {
                      cursor: pointer;
                      background-color: red;
                    }
                  }
                }
              }
            }
          }

          .category {

            .warning {
              width: 80%;
            }


            .switch {
              position: relative;
              display: inline-block;
              width: 60px;
              height: 34px;
            }

            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 26px;
              width: 26px;
              left: 4px;
              bottom: 4px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            input:checked+.slider {
              background-color: var(--secondary-color);
            }

            input:focus+.slider {
              box-shadow: 0 0 1px var(--secondary-color);
            }

            input:checked+.slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }

            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }
        }
      }

      .actionButtons {
        float: right;

        .register {
          margin-right: 1rem;
        }
      }
    }
  }

  @include desktop {
    padding: 0;

    .registerEmployeeForm {
      form {
        .formInputs {
          flex-flow: row;

          .col {

            .checkboxReverse {
              width: unset;
            }

            .formGroup {
              flex-flow: row;
              margin-bottom: 1rem;

              label {
                margin-bottom: 5px;
              }

              input:not([type="checkbox"]),
              select {
                height: 33px;
                width: 200px;
              }
            }

            .skills {
              .multiselect {
                flex-flow: column;
                width: 200px;

                .selectedOptions {

                  .select {
                    display: flex;

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}