.exchange-card-container {
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  max-width: 100%;
  margin: 20px 0;
  overflow: hidden;
  background-color: #fff;

  @include medium {
    flex-flow: row;
  }
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
}

.exchange-card-preview {
  width: 100%;
  height: 100%;

  @include laptop {
    max-width: 300px;

    .image-holder {

      img {
        max-width: 300px;
      }
    }

  }

  .image-holder {
    background-color: var(--secondary-color);
    width: 100%;

    img {
      padding: 10px;
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }



  .exchange-company-information {
    background-color: #fff;
    padding: 20px;
    border-right: 1px dotted var(--secondary-color);

    .company-name {
      font-weight: 800;
      text-transform: capitalize;
      font-size: larger;
    }

    div {

      i {
        color: var(--secondary-color);
        float: right;
        font-weight: 600;
      }
    }
  }

  @include tablet {
    width: 50%;
  }
}

.expandDetails {
  margin: 2rem 0;
}

.exchange-card-info {
  padding: 0 30px 30px 30px;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  gap: 5px;

  .exchange-employee-data {
    display: flex;
    flex-flow: column;
    gap: 10px;

    .employee-content {
      flex: 1;

      &:first-child {
        border-bottom: 2px solid var(--secondary-color);
      }

      @include laptop {

        &:first-child {
          border-right: 2px solid var(--secondary-color);
          border-bottom: none;
        }
      }
    }
  }

  h4 {
    font-weight: 700;
    text-transform: capitalize;
  }

  h6 {
    color: var(--secondary-color);
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial;
    margin-bottom: 5px;

  }

  .exchange-card-footer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-top: auto;

    .request-action-button {
      margin-left: auto;
      display: flex;
      flex-flow: column;
      gap: 10px;

      .employee-salary {
        font-size: larger;
        text-align: right;
      }
    }

    .tags-and-location {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .job-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-right: auto;
      }

      span {
        margin-right: 5px;

        &:first-child {
          color: var(--secondary-color);
          font-weight: bolder;
        }

        i {
          margin-right: 5px;
          color: var(--secondary-color);
        }
      }
    }
  }

  @include laptop {
    padding: 30px;

    .exchange-employee-data {
      flex-flow: row;
    }
  }
}

.date-container {
  position: absolute;
  top: 30px;
  right: 30px;
  text-align: right;
  width: 150px;

  @include medium {
    top: 30px;
  }
}

.employee-progress-text {
  font-size: 14px;
  opacity: 0.9;
  letter-spacing: 1px;
  width: max-content;
}