$color-green: #4bb7a9;

.employeeInformation {

  .employeeDetails {
    background-color: var(--primary-color);
    color: white;
    padding: 2rem;

    .detailsContainer {
      display: flex;
      flex-flow: column;
      padding: 2rem 0;
      gap: 1rem;

      div {
        flex: 50%;

        ul {
          font-size: 15px;
          display: flex;
          flex-flow: column;
          height: 100%;
          border-bottom: 2px solid var(--secondary-color);

          .certifiedRow {




            .certified {
              display: flex;
              gap: 0.5rem;
              flex: unset;
              width: 100%;
            }
          }

          li {
            padding: 1rem 0;
          }

          span {
            font-weight: bold;
          }

          .workHistory {
            display: flex;
            flex-flow: column;
          }
        }
      }
    }

    form {
      display: flex;
      flex-flow: column;
      row-gap: 0.5rem;

      .formInputs {
        display: flex;
        flex-flow: column;
        gap: 2rem;

        .col {
          height: 100%;

          &:first-child {
            padding-bottom: 1rem;
          }

          .multiselect-clicked-options {
            flex-flow: column;
            margin-top: 20px;

            .multiselect-item {
              display: flex;
              margin-right: 1rem;
              gap: 0.25rem;
              align-items: center;
              color: black;
              background-color: whitesmoke;
              border-radius: 4px;
              margin-bottom: 4px;
              padding: 4px;
              width: fit-content;

              span {
                cursor: pointer;

                &:hover {
                  background-color: #DC3545;

                  svg {
                    color: white;
                  }
                }

              }


            }
          }

          .certifiedRow {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            justify-content: space-between;
          }

          .checkboxReverse {
            display: flex;
            gap: 0.5rem;
            width: 100%;
            justify-content: space-between;

            input[type="checkbox"] {
              height: 22px;
              width: 22px;
              accent-color: var(--secondary-color);
            }
          }



          div:not(.multiselectdropdown-body):not(.contact-multiselectdropdown) {
            label {
              margin: 10px 0;
              width: 100%;
            }

            input,
            select {
              height: 33px;
              margin: 10px 0;
              width: 100%;

              &:disabled {
                background-color: var(--primary-color);
                color: white;
                filter: brightness(1.75);
              }
            }

            input[type="checkbox"] {
              height: 26px;
              width: 26px;
              accent-color: var(--secondary-color);
            }

            .warning {
              label {
                max-width: 100%;
              }
            }

            .switch {
              position: relative;
              display: inline-block;
              width: 60px;
              height: 34px;
            }

            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 26px;
              width: 26px;
              left: 4px;
              bottom: 4px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            input:checked+.slider {
              background-color: var(--secondary-color);
            }

            input:focus+.slider {
              box-shadow: 0 0 1px var(--secondary-color);
            }

            input:checked+.slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }

            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }

          .skills {
            margin: unset;

            .multiselect {
              display: flex;
              flex-flow: column;
              width: 50%;
              margin: unset;

              .selectedOptions {
                margin: unset;
                padding: 0.5rem;
                background-color: white;
                color: black;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 0.3rem;

                height: auto;

                .select {
                  margin: unset;
                  background-color: var(--secondary-color);
                  color: white;

                  span {
                    padding: 0 5px;
                    color: black;

                    &:hover {
                      cursor: pointer;
                      background-color: red;
                    }
                  }
                }

                .c-multi-select-dropdown {
                  &__selected {
                    margin: unset;
                  }
                }
              }
            }
          }

          .workHistory {
            display: flex;
            flex-flow: column;
            align-items: flex-end;
            padding: 1rem 0;
          }
        }
      }
    }
  }

  @include desktop {
    .employeeDetails {
      .detailsContainer {
        flex-flow: row;

        .left {
          border-right: 2px solid var(--secondary-color);
        }

        div {


          ul {
            border-bottom: none;


            .certifiedRow {
              .certified {
                width: unset;
              }
            }
          }

          span {
            float: right;
            padding-right: 2rem;
          }

          .workHistory {
            float: right;
          }
        }
      }

      form {
        .formInputs {
          flex-flow: row;

          .col {
            padding: 2rem 0;


            .checkboxReverse {
              width: unset;
            }

            &:first-child {
              padding-right: 2rem;
              position: relative;
              border-bottom: none;
            }

            .multiselect-clicked-options {
              flex-flow: column;

              .multiselect-item {
                display: flex;
                margin-right: 1rem;
                gap: 0.25rem;
                align-items: center;
                color: black;
                background-color: whitesmoke;
                border-radius: 4px;
                margin-bottom: 4px;
                padding: 4px;
                width: fit-content;

                span {
                  cursor: pointer;

                  &:hover {
                    background-color: #DC3545;

                    svg {
                      color: white;
                    }
                  }

                }


              }
            }

            div:not(.multiselectdropdown-body):not(.contact-multiselectdropdown) {
              display: flex;
              justify-content: space-between;




              input:not([type="checkbox"]),
              select {
                width: 50%;
              }

              .warning {
                label {
                  max-width: 95%;
                  word-wrap: break-word;
                }
              }
            }
          }
        }

        .actionButtons {
          align-self: flex-end;
          float: right;
        }
      }
    }
  }
}