.job-card-container {
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  max-width: 100%;
  margin: 20px 0;
  overflow: hidden;

  @include medium {
    flex-flow: row;
  }
}

.job-card-preview {
  width: 100%;
  height: 100%;

  @include laptop {
    max-width: 300px;

    .image-holder {

      img {
        max-width: 300px;
      }
    }

  }

  .image-holder {
    background-color: var(--secondary-color);
    width: 100%;

    img {
      padding: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .company-information {
    background-color: #f5f5f5;
    padding: 20px;
    border-right: 1px dotted var(--secondary-color);

    .company-name {
      font-weight: 800;
      text-transform: capitalize;
      font-size: larger;
    }

    div {

      i {
        color: var(--secondary-color);
        float: right;
        font-weight: 600;
      }
    }
  }

  @include tablet {
    width: 50%;
  }
}

.job-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: auto;
  margin-right: auto;

}

.job-card-info {
  padding: 0 20px 20px 20px;
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-flow: column;

  h4 {
    font-weight: 700;
    text-transform: capitalize;
  }

  h6 {
    color: var(--secondary-color);
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial;
    margin-bottom: 5px;

  }

  div {
    .timeline {
      font-weight: 600;
      margin-right: 10px;

    }

  }

  .job-tags {
    width: 100%;
    display: flex;
    align-items: flex-end;

    a {
      margin-left: auto;
    }

    span {
      &:first-child {
        color: var(--secondary-color);
      }

      i {
        margin-right: 5px;
        color: var(--secondary-color);
      }
    }
  }

  @include medium {
    padding: 30px;
  }
}

.date-container {
  position: absolute;
  top: 0px;
  right: 30px;
  text-align: right;

  @include medium {
    top: 30px;
  }
}

.job-description {
  width: 100%;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0.5rem 0;
}