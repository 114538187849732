.notifications-container {

  .notification-navbar {
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    a {
      height: 100%;
      width: 100%;

      font-size: large;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #11a08d;

        i {
          margin-right: 8px;
        }

        &.req-rec {
          color: white;
          background-color: #4f4f4f;
          border: 2px solid white;
          border-radius: 4px;
        }

        &.req-rec-passive {
          background-color: #696969;
        }

        &.req-sent {
          background-color: #44b5a7;
          border: 2px solid white;
          border-radius: 4px;
          color: white;
        }

        &:not(.req-rec):not(.req-sent) {
          transition: 0.2s ease-in-out;

          &:hover {
            transform: scale(1.02);
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  }

  .tabs-container {

    .notification-page {
      margin-bottom: 0 !important;

      &.outgoing {


        .nav-item {
          background-color: var(--secondary-color);

          .nav-link {
            color: white;
            min-height: 60px;
            font-size: 22px;

            &.active {
              background-color: var(--primary-color);

              min-height: 60px;
              font-weight: 600;

            }
          }
        }
      }

    }


    .notification-tabs {
      min-height: 60px;

      &.outgoing {


        .nav-item {
          background-color: #11a08d;

          .nav-link {
            color: white;
            min-height: 60px;
            font-size: 22px;

            &.active {
              background-color: var(--secondary-color);
              min-height: 60px;
              font-weight: 600;

            }
          }
        }
      }

      &.incoming {
        .nav-item {
          background-color: #695e5e;

          .nav-link {
            color: white;
            min-height: 60px;
            font-size: 22px;

            &.active {
              background-color: var(--primary-color);
              min-height: 60px;
              font-weight: 600;

            }
          }
        }
      }

    }


    display: flex;
    flex-flow: column;

    color: white;

    .Dropdown-Wrapper {
      width: 200px;

      margin-top: 20px;

      .Dropdown-Tip {

        .tab {
          margin-bottom: 4px;
          border-radius: 4px;
          padding-inline: 4px;
          background-color: white;

          &.Begärda {
            color: #1c66a3;
            border: 2px solid #1c66a3;

            i {
              color: #1c66a3;
            }
          }

          &.Godkänd {
            color: var(--secondary-color);
            border: 2px solid var(--secondary-color);

            i {
              color: var(--secondary-color);
            }
          }

          &.Nekad {
            color: #e69225;
            border: 2px solid #e69225;

            i {
              color: #e69225;
            }
          }
        }
      }
    }

    .tabs {
      &.incoming {
        background-color: #696969;

        .tab.active {
          background-color: rgba(42, 42, 42, 0.4);
        }
      }

      &.outgoing {
        flex-flow: row-reverse;
        background: linear-gradient(60deg, #128878, #29b4a2);

        .tab.active {
          background-color: rgba(75, 183, 169, 0.8);
        }
      }

      display: flex;
      justify-content: space-between;

      .tab {
        flex: 0.33;
        line-height: 2.5;
        text-align: center;

        span {
          i {
            width: 100%;
          }
        }
      }

      .tab:hover {
        cursor: pointer;
        animation: pulse-white 0.8s ease-in-out;
      }

      .tab.active {
        background-color: rgba(75, 183, 169, 0.8);
        background-color: rgba(42, 42, 42, 0.4);
      }
    }

    .tab-content {
      margin-top: 10px;
      min-height: 300px;




    }
  }

  @include desktop {
    .tabs-container {
      .tab-content {
        .company-card {
          flex-flow: row;

          &-header {
            flex: auto;
          }
        }
      }
    }
  }
}