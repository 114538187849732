$green: #75b800;
$gray-light: #eef1f3;

$bar-size: 5px;
$bar-radius: 60px;
$bar-bg: rgba(0, 0, 0, 0.075);

@keyframes loader {
  0% {
    width: 0;
  }
}

.progress-bar {
  border-radius: $bar-radius;
  overflow: hidden;
  width: 100%;

  span {
    display: block;
  }
}

.bar {
  background: $bar-bg;
}

.progress {
  animation: loader 8s ease infinite;
  background: $green;
  color: #fff;
  padding: $bar-size;
  width: 0;
}

.progress-bar {
  left: 50%;
  max-width: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}