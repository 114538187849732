.dashboardHeader {
  padding: 2rem 0;

  .miniNavbar {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    row-gap: 1rem;

    .statistics {
      div {
        &:hover {
          cursor: pointer;
        }
      }

      @include tablet {
        display: flex;
        flex-flow: row;
        column-gap: 1rem;
        align-items: center;
      }
    }

    .actions {
      @include desktop {
        display: flex;
        flex-flow: row;
        column-gap: 1rem;
        justify-content: flex-end;
      }
    }

    span {
      padding-right: 0.4rem;
    }

    @include tablet {
      flex-flow: column;
    }

    @include desktop {
      flex-flow: row wrap;
    }
  }
}