.companyProfile {
  display: flex;
  flex-flow: column;
  justify-content: center;

  .companyInformation {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 100%;
    padding: 1rem;
    color: white;
    background-color: var(--primary-color);
    line-height: 1.5;

    .column {
      .columnHeader {
        padding-bottom: 1rem;
      }
    }

    ul {
      li {
        .settings {
          font-weight: bolder;
          font-size: 16px;
        }
      }
    }
  }

  @include tablet {
    .companyInformation {
      flex-flow: column;
    }
  }

  @include desktop {
    gap: 2rem;
    font-family: var(--primary-font);
    justify-content: center;

    .companyInformation {
      flex-flow: row;
      width: 100%;
      padding: 2rem;
      justify-content: flex-start;

      .column {
        flex: 0.5;

        ul {
          display: flex;
          flex-flow: column;
          gap: 1rem;
        }
      }
    }
  }
}