.mobile-card-header.secondary {
  border-radius: 3px;
  padding: 15px;
  margin: 20px 15px;
  color: #fff;

  background: linear-gradient(60deg, #2a2a2a, #695e5e);
  &.secondary {
    background: linear-gradient(60deg, #128878, #4bb7a9);
  }
}

.card-container {
  margin: 15px 25px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px;
  .columns-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    .mobile-row {
      display: flex;
      word-break: break-all;
      margin: 2px 0;
      .label {
        border-right: 1px solid var(--secondary-color);
        color: var(--secondary-color);
        font-size: 1rem;
        margin-right: 2px;
      }
      .value{
        margin-left: 5px
      }
      span {
        width: 100%;
      }
    }
  }
  .actions {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
  }
}
