/* Custom properties */
:root {
  --Dropdown-text-color: white;
  --Dropdown-background-color: black;
  --Dropdown-margin: 30px;
  --Dropdown-arrow-size: 6px;
}

/* Wrapping */
.Dropdown-Wrapper {
  position: relative;
  height: 39px;
  min-width: 50px;
  line-height: 39px;
  border-radius: 4px;
  padding-inline: 6px;
  border: 2px solid black;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  font-weight: 600;

  color: black;

  i {
    float: right;
    line-height: 39px;
  }

  &.Begärda {
    color: #1c66a3;
    border: 2px solid #1c66a3;
    min-width: 120px;
    background-color: transparent;

    i {
      color: #1c66a3;
    }

    &:hover {
      color: #043c69;
    }
  }

  &.Godkänd {
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    min-width: 120px;

    i {
      color: var(--secondary-color);
    }
  }

  &.Nekad {
    min-width: 120px;

    color: #dc3545;
    border: 2px solid #dc3545;


    i {
      color: #dc3545;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

/* Absolute positioning */
.Dropdown-Tip {
  position: absolute;
  top: 38px;
  width: 100%;
  height: fit-content;
  margin-inline: -6px;
  padding-block: 6px;
  color: var(--Dropdown-text-color);

  font-size: 14px;
  font-family: sans-serif;
  z-index: 100;
  white-space: nowrap;

  .dropdown-option {
    margin-bottom: 4px;
    border-radius: 4px;
    padding-inline: 4px;
    background-color: white;
    color: black;
    text-align: center;
    border: 2px solid black;
  }

  .tab {
    margin-bottom: 4px;
    border-radius: 4px;
    padding-inline: 4px;
    background-color: white;

    &.Begärda {
      color: #1c66a3;
      border: 2px solid #1c66a3;

      i {
        color: #1c66a3;
      }
    }

    &.Godkänd {
      color: var(--secondary-color);
      border: 2px solid var(--secondary-color);

      i {
        color: var(--secondary-color);
      }
    }

    &.Nekad {

      color: #dc3545;
      border: 2px solid #dc3545;

      i {
        color: #dc3545;
      }
    }
  }
}

/* CSS border triangles */

/* Absolute positioning */
.Dropdown-Tip.top {
  top: calc(var(--Dropdown-margin) * -1);
}

/* CSS border triangles */
.Dropdown-Tip.top::before {
  top: 100%;
  border-top-color: var(--Dropdown-background-color);
}

/* Absolute positioning */
.Dropdown-Tip.right {
  left: calc(100% + var(--Dropdown-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Dropdown-Tip.right::before {
  left: calc(var(--Dropdown-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--Dropdown-background-color);
}

/* Absolute positioning */
.Dropdown-Tip.bottom {
  bottom: calc(var(--Dropdown-margin) * -1);
}

/* CSS border triangles */
.Dropdown-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--Dropdown-background-color);
}

/* Absolute positioning */
.Dropdown-Tip.left {
  left: auto;
  right: calc(100% + var(--Dropdown-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Dropdown-Tip.left::before {
  left: auto;
  right: calc(var(--Dropdown-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--Dropdown-background-color);
}