.edit-company-container {
  width: 100%;

  .edit-header {
    padding: 50px 0;
    display: flex;
    justify-content: center;

    .title {
      font-size: 40px;
    }
  }

  .edit-form {
    margin: 0 30px;
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 0;

    .form-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 25px;
      padding: 20px 0;

      .line {
        width: 80%;
        height: 1px;
        background-color: var(--secondary-color);
        margin: 10px 0;
      }
    }

    .form-inputs {
      margin: 0 20px;

      .form-group {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        input:disabled {
          color: white;
          font-weight: 700;
        }

        select:disabled {


          color: white;
          font-weight: 700;

        }
      }

      .buttons {
        align-items: flex-end;
      }
    }
  }
}

@include tablet {
  .edit-company-container {
    .edit-form {
      .form-inputs {
        form {
          width: 100%;
          display: flex;
          flex-direction: row;

          .col {
            display: flex;
            flex-direction: column;
            padding: 0 20px;

            input {
              height: 30px;
            }

            select {
              height: 30px;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .edit-company-container {
    .edit-header {
      justify-content: flex-start;
      margin: 0 30px;
    }

    .edit-form {
      .form-title {
        align-items: flex-start;
        margin: 0 20px;
      }

      .form-inputs {
        form {
          .col {
            input {
              width: 50%;
            }

            select {
              width: 50%;
            }

            .buttons {
              width: 50%;
            }
          }
        }
      }
    }
  }
}