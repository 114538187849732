.pill-container {
  background-color: var(--primary-color);
  color: white;
  font-weight: 500;
  font-size: 11px;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 4px;
  max-width: fit-content;

  &::first-letter {
    text-transform: capitalize;
  }

  &.secondary {
    background-color: var(--secondary-color);

    &:hover {
      cursor: pointer;
    }

  }

  &.bolder {
    font-weight: 500;
    font-size: 14px;
  }
}