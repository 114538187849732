//Image as background
@mixin image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin medium {
  @media screen and (min-width: 580px) {
    @content;
  }
}

//Media Queries
@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin navbar {
  @media screen and (max-width: 768px) {
    @content;
  }
}