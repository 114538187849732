.tou-container {
  .top-image-container {
    width: 100%;
    background-image: url(../assets/images/10.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .background {
      background: linear-gradient(
        90deg,
        rgba(42, 42, 42, 0.8) 50%,
        rgba(75, 183, 169, 0.8) 50%
      );
      .terms-of-use-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        span {
          font-family: var(--primary-font);
        }
        .title {
          padding: 20px 0 20px 20px;
          font-weight: bold;
          font-size: 20px;
          color: $secondary-color;
        }
        .terms-of-use {
          padding: 20px 0 20px 20px;
          font-size: 40px;
          color: #fff;
          font-weight: lighter;
        }
      }
    }
  }
  .tou-content {
    .tou-desc {
      padding: 10px;
      span {
        font-size: 20px;
        color: $secondary-color;
        font-weight: 400;
      }
    }
    .tou-title {
      padding: 10px;
      font-size: 36px;
    }
    .divider {
      border-bottom: 1px solid black;
      width: 80%;
      height: 1px;
      margin: 10px 0;
    }
    .terms {
      .term {
        .term-title {
          padding: 10px;
          font-size: 24px;
        }
        ol {
          padding: 15px;
          list-style-type: decimal;
          list-style-position: inside;
        }
        ol li {
          margin: 10px 0;
        }
      }
    }
  }
  .bottom-image-container {
    width: 100%;
    height: 180px;
    background-image: url(../assets/images/1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@include laptop {
  .tou-container {
    .tou-content {
      padding: 0 150px;
      .terms {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
