.unauthorized-container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 40px;
    font-weight: bold;
    font-family: var(--primary-font);
    padding: 0 20px;
    text-align: center;
    margin-bottom: 50px;
  }
  .go-back-button {
    .go-back {
      padding: 10px 20px;
      font-size: 20px;
      background-color: $secondary-color;
      border: none;
    }
    .go-back:hover {
      background-color: $primary-color;
      border: none;
    }
    .go-back:focus {
      background-color: $primary-color;
    }
    .go-back:active {
      background-color: $primary-color;
    }
  }
}
