.create-jobpost {
  width: 90%;
  padding: 1rem;

  .create-jobpost-container {
    color: white;
    background-color: var(--primary-color);
    padding: 2rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;

    form {


      .jobpost-information {
        display: flex;
        gap: 2rem;
        margin-block: 2rem;
        padding-bottom: 25px;
        border-bottom: 2px solid var(--secondary-color);

        .information-column {
          flex: 1;
          display: flex;
          flex-flow: column;
          gap: 1rem;
          justify-content: flex-end;

          .formGroup {
            .reset {
              width: unset;
            }

            &.flex {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }

            label {
              width: 100%;
              margin-bottom: 10px;
              font-size: 18px;
            }

            input[type="text"],
            input[type="date"],
            textarea {
              width: 100%;
              height: 33px;
            }

            .contact-question {
              display: flex;
              gap: 2rem;

              .contact-options {
                display: flex;
                gap: 1rem;
              }

              margin-bottom: 20px;
            }

          }
        }
      }

      .jobpost-employees {

        .employees-title {
          font-size: 24px;
          margin-block: 2rem;
        }

        .employees-wanted {
          .employee-container {
            .employees-formGroup {
              .employee-bar {
                display: flex;
                flex-flow: column;
                gap: 2rem;
                border: 2px solid white;
                padding: 10px;
                border-radius: 12px;
                margin-bottom: 20px;

                .jobpost-input {
                  display: flex;
                  justify-content: space-between;

                  gap: 2rem;

                  .groupedInputs {
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;
                    flex-flow: column;


                    .formGroup:not(:first-child) {
                      flex: 1;
                    }
                  }

                  > :first-child {
                    input {
                      width: 100px;
                    }
                  }

                  .formGroup {
                    label {
                      width: 100%;
                      margin-bottom: 10px;
                      font-size: 18px;
                    }

                    textarea,
                    select {
                      width: 100%;
                    }
                  }
                }

                .add-inputs {
                  &:hover {
                    background-color: var(--secondary-color);
                    color: black;
                  }

                  align-self: center;
                  cursor: pointer;
                  border-radius: 12px;
                  width: 50%;
                  padding: 1rem;
                  text-transform: capitalize;
                  border: 2px dotted var(--secondary-color);
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .create-jobpost-actions {
        float: right;
      }
    }
  }

  @include tablet {
    .create-jobpost-container {
      form {
        .jobpost-information {
          flex-flow: row;
        }

        .jobpost-employees {
          .jobpost-input {
            .employees-wanted {
              .employee-container {
                .employees-formGroup {
                  .employee-bar {
                    .jobpost-input {
                      flex-flow: row;

                      .groupedInputs {
                        flex-flow: row;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}